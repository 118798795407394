export enum WidgetType {
    Rooms = 'rooms',
    Schedule = 'schedule',
    Subscriptions = 'subscriptions',
    Courses = 'courses',
    Coaches = 'coaches',
    Payment = 'payment',
    PurchaseSubscription = 'purchaseSubscription',
    Certificates = 'certificates'
}

export interface WidgetSettings {
    widgetId: string;
    clubId: string;
    widgetType: WidgetType;
    timeZone: number;
    settings: WidgetConfigBase;
    dependenceWidgetId: string;
    culture?: string;
    courseId?: string;
    TimeMatrix?: any[];
    rooms?: any[];
}

export interface WidgetConfigBase {
    [key: string]: any;
    style: string;
}

export interface CertificatesWidgetSettings extends WidgetSettings {
  settings: any;
}

export interface ScheduleWidgetConfig extends WidgetConfigBase {
    // TODO: настройка для отображения пустых строк в расписании
    // showEmptyRows: boolwan
    showRoomFilter: boolean;
    showGroupFilter: boolean;
    showCoursesFilter: boolean;
    showCoachesFilter: boolean;
    showTrainingCategoryFilter: boolean;
    showLevelFilter: boolean;
    showClubNet: boolean;
    roomId: string;
    disableSubscribeOnClass: boolean;
    showBirthday: boolean;
    isBookingScheduleAllowed: boolean;
    isSubscriptionSellAllowed: boolean;
    scheduleWidgetId?: string;
    widgetName: string;
    dictionaryAlias?: string;
    disableRoom: boolean;
    singleEntryForm?: boolean;
    hideSbp?: boolean;
    visibilityDescSub: VisibilityDescriptionSub;
    availableInfo: AvailableInfo;
    isFillComment: boolean;
    placeholderComment: string;
    isAllowSubPriceChange?: boolean;
    isRemoveCustomPayment: boolean;
  }

export interface ScheduleWidgetSettings extends WidgetSettings {
    settings: ScheduleWidgetConfig;
}

export enum PaymentGateway {
  Unknown = 'Unknown',
  Sbp = 'Sbp'
}

export enum VisibilityDescriptionSub {
  HideEveryone,
  Desktop,
  Mobile,
  ShowEveryone
}

export enum AvailableInfo {
  Everyone,
  Registered,
  Anonymous
}

export interface IMouse {
  isMouseDown: boolean;
  isMouseMove: boolean;
  positionX:   number;
  positionY:   number;
}

export interface IScroll {
  isScrolling:           boolean;
  scrollBarWidth:        number;
  scrollButtonWidth:     number;
  scrollBarPositionX:    number;
  scrollButtonPositionX: number;
}

export interface ITableHeader {
  position: number;
  offset:   number;
  stick:    boolean;
}
